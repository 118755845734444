<template>
  <div>
    <v-carousel cycle interval="5000" class="carousel--wrapper">
      <v-carousel-item v-for="(testimony, index) in testimonies" :key="`testimony-${index}`">
        <div class="carousel--slide">
          <div class="testimony--content">
            <div class="t--name">{{ testimony.name }}</div>
            <div class="t--role-company">
              {{ `${testimony.role} ${testimony.company ? '-' : ''} ${testimony.company}` }}
            </div>
            <div class="t--message">{{ testimony.message }}</div>
          </div>
          <img :src="testimony.img" :alt="testimony.name" class="carousel--image img-responsive" />
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  computed: {},
  data: () => ({
    testimonies: [
      {
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/testimonies/ricky_saputra.png',
        name: 'Ricky Saputra',
        company: 'PT Green Alam Lestari',
        role: 'Direktur',
        message:
          'Di era sekarang perlu sekali mengikuti dan mempelajari digital marketing yang dapat membantu peningkatan penjualan. Salah satunya BeliRumah.co. Platform yang memudahkan kita untuk berjualan anti ribet dan capek.',
      },
      {
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/testimonies/dedy_lo.png',
        name: 'Dedy Lo',
        company: 'Emerald land',
        role: 'Head Project',
        message:
          'Platform property yang cukup profesional dengan SDM yang ramah, sopan, dan Koorporatif.',
      },
      {
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/testimonies/mayo_azhari.png',
        name: 'Mayo Azhari',
        company: 'PT Indopasifik Indahtama',
        role: 'General Manager',
        message:
          'BeliRumah sebagai sebuah tempat, wadah, atau sarana yang memfasilitasi bertemunya para pihak untuk saling bertukar informasi properti. Top keren & semakin sukses 🙏',
      },
      {
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/testimonies/wulan_noviandini.png',
        name: 'Wulan Noviandini',
        company: '',
        role: 'Konsumen BeliRumah',
        message:
          'Pengalaman mencari rumah melalui BeliRumah.co sangat mudah, dibantu sales yang berpengalaman, komunikatif dan fast response. Dan yang terpenting bisa dapat bonus cashback 2 juta. Terimakasih BeliRumah.co.',
      },
      {
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/testimonies/muhamad_rafiqi.png',
        name: 'Muhamad Rafiqi',
        company: '',
        role: 'Konsumen BeliRumah',
        message:
          'Aplikasi BeliRumah sangat membantu untuk mencari rumah subsidi ataupun non subsidi, respon dari CS tanggap, cepat dan ramah, selalu siap membantu jika ada kendala, Terima kasih BeliRumah.',
      },
      {
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/testimonies/tharmidzi_hermawan.png',
        name: 'Tharmidzi Hermawan',
        company: 'Prabuana Karya Mandiri',
        role: 'Marketing Support',
        message:
          'Luar biasa bisa bersinergi dengan BeliRumah, portal properti buatan anak bangsa yang sangat membantu kami sebagai developer dengan dukungan yang responsif dari tim BeliRumah cukup bisa diandalkan untuk memasarkan properti ke berbagai kalangan dari MBR sampai menengah ke atas.',
      },
      {
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/testimonies/panji_sahid.png',
        name: 'Panji Rahmat Sahid',
        company: 'IntiMitbana',
        role: 'Agent Coordinator Green Bestari Park',
        message: 'Bagus dan rapi jadi memudahkan calon konsumen dalam mencari properti.',
      },
      {
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/testimonies/andreas_wijaya.png',
        name: 'Andreas Iwan Wijaya',
        company: 'Nexxpro',
        role: 'Principal',
        message:
          'Kerjasama yang terjalin sangat baik. BeliRumah.co sering info database untuk dibantu closing oleh kami selaku lead agent.',
      },
      {
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/testimonies/syafriadi_hutagalung.png',
        name: 'Syafriadi Hutagalung',
        company: 'RDP Realty',
        role: 'Principal',
        message:
          'BeliRumah adalah platform portal properti yang akan menjadi platform terbaik di Indonesia, dengan semakin tingginya persaingan bisnis properti digital, BeliRumah harus bisa meningkatkan kualitas strategi bisnisnya untuk menjadi platform terbaik dan nomor 1.',
      },
      {
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/testimonies/dian_setiawati.png',
        name: 'Dian Kurniasih Setiawati',
        company: 'PT AD Rumah Sejahtera Properti',
        role: 'Principal',
        message:
          'Kami telah bekerja sama dalam pertukaran data, bahkan membantu dengan kunjungan ke lokasi. Saya berharap kerjasama antara kami dan BeliRumah dapat terus berlanjut, dan kami dapat terus berkontribusi untuk kerjasama yang berkesinambungan. Terima kasih.',
      },
    ],
  }),
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
.carousel--wrapper {
  height: 320px !important;
  @media #{$phones} {
    height: 390px !important;
  }
}
.carousel--slide {
  height: 260px;
  display: flex !important;
  align-items: center;
  padding: 8px 56px;
  @media #{$tablets} {
    height: 280px;
  }
  @media #{$phones} {
    //width: calc(100vw - 32px);
    height: 340px;
    flex-wrap: wrap-reverse;
    padding: 8px 4px;
  }
  .testimony--content {
    //width: 500px;
    height: auto;
    margin-right: 48px;
    text-align: right;
    @media #{$tablets} {
      margin-right: 24px;
    }
    @media #{$phones} {
      width: 100%;
      margin-right: 0;
      text-align: center;
      padding: 0 24px;
    }
    .t--name {
      font-family: Poppins-SemiBold;
      font-size: $font-xxl;
      margin-bottom: 4px;
      @media #{$tablets} {
        font-size: $font-xl;
      }
      @media #{$phones} {
        font-size: $font-lg;
        margin-bottom: 2px;
      }
    }
    .t--role-company {
      font-size: $font-ml;
      margin-bottom: 8px;
      @media #{$tablets} {
        font-size: $font-md;
      }
      @media #{$phones} {
        font-size: $font-sm;
        margin-bottom: 4px;
      }
    }
    .t--message {
      color: $color-text-gray-3;
      font-style: italic;
      //noinspection CssInvalidPropertyValue
      text-wrap: wrap;
      @media #{$tablets} {
        font-size: $font-sm;
      }
      @media #{$phones} {
        font-size: $font-xs;
      }
    }
  }
  .carousel--image {
    width: 240px;
    height: 240px;
    background-color: $color-white;
    border-radius: 24px;
    box-shadow: $box-shadow;
    object-fit: cover;
    @media #{$tablets} {
      width: 160px;
      height: 160px;
    }
    @media #{$phones} {
      display: block;
      width: 140px;
      height: 140px;
      margin: 0 auto;
    }
  }
}
</style>
